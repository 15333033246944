import { render, staticRenderFns } from "./Square.vue?vue&type=template&id=f5f80976&scoped=true&"
import script from "./Square.vue?vue&type=script&lang=js&"
export * from "./Square.vue?vue&type=script&lang=js&"
import style0 from "./Square.vue?vue&type=style&index=0&id=f5f80976&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5f80976",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/prod--showmoment/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f5f80976')) {
      api.createRecord('f5f80976', component.options)
    } else {
      api.reload('f5f80976', component.options)
    }
    module.hot.accept("./Square.vue?vue&type=template&id=f5f80976&scoped=true&", function () {
      api.rerender('f5f80976', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/square/Square.vue"
export default component.exports