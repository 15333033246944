var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("div", { staticClass: "header" }, [
        _c("span", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("lang.discover"))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "pull-refresh-list",
            {
              attrs: {
                list: _vm.list,
                search: _vm.search,
                getPageData: _vm.getPageData,
              },
            },
            _vm._l(_vm.list, function (item) {
              return _c(
                "div",
                {
                  staticClass: "item",
                  on: {
                    click: function ($event) {
                      return _vm.toDetail(item.buzzTxId)
                    },
                  },
                },
                [
                  item.fileDataType.includes("video/")
                    ? [
                        _c("video", {
                          staticClass: "photo",
                          attrs: {
                            src: _vm._f("videoFilters")(item.txId),
                            poster: _vm.getPoster(item.txId),
                            "webkit-playsinline": "true",
                            playsinline: "true",
                            "x5-playsinline": "true",
                            "object-fit": "contain",
                          },
                        }),
                      ]
                    : [
                        _c("van-image", {
                          staticClass: "photo",
                          attrs: { src: item.resUrl, fit: "cover", alt: "" },
                        }),
                      ],
                  _c("div", {
                    class: {
                      icon_img: item.fileDataType.includes("image/"),
                      icon_video: item.fileDataType.includes("video/"),
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "avatar",
                      staticStyle: { display: "flex", "align-items": "center" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.goUser(item.metaId, item.zeroAddress)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "18px",
                          height: "18px",
                          "border-radius": "50%",
                          cursor: "pointer",
                        },
                        attrs: { src: _vm._f("avatarUrl")(item.metaId) },
                      }),
                      _c("div", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(_vm._s(item.userName)),
                      ]),
                    ]
                  ),
                ],
                2
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }