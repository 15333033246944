<template>
  <div class="wrapper">
    <div class="header">
      <span class="title">{{ $t('lang.discover') }}</span>
    </div>
    <div class="content">
      <pull-refresh-list
          :list="list"
          :search="search"
          :getPageData="getPageData"
      >
        <div class="item" v-for="item in list" @click="toDetail(item.buzzTxId)">
          <template v-if='item.fileDataType.includes("video/")'>
            <video :src="item.txId | videoFilters" :poster="getPoster(item.txId)"
                   webkit-playsinline="true" playsinline="true" x5-playsinline="true" object-fit="contain" class="photo" />
          </template>
          <template v-else>
            <van-image :src="item.resUrl" fit="cover" alt="" class="photo" />
          </template>
          <div :class="{icon_img: item.fileDataType.includes('image/'), icon_video: item.fileDataType.includes('video/')}"></div>
          <div class="avatar" @click.stop="goUser(item.metaId, item.zeroAddress)" style="display: flex; align-items: center">
            <img :src="item.metaId | avatarUrl" style="width: 18px;
          height: 18px;
          border-radius: 50%;
          cursor: pointer;">
            <div style="margin-left: 10px">{{item.userName}}</div>
          </div>
        </div>
      </pull-refresh-list>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {getPiazzaList} from 'api/api'
import PullRefreshList from '@/components/list/PullRefreshList'
export default {
  name: "Square",
  components: {
    PullRefreshList
  },
  data() {
    return {
      list: [],
      search: {
        metaId: "",
        timestamp: 0
      }
    }
  },
  computed: {
    getPoster () {
      return (value) => {
        if (!value) return ''
        return `${process.env.VUE_APP_AppImgApi}/metafile/videoImg/${value}`
      }
    }
  },
  methods: {
    getPageData(params) {
      params.metaId = localStorage.getItem('showId')
      return getPiazzaList(params).then(res => {
        res.data.results.items.forEach(item => {
          item.resUrl = item.resUrl.replace(process.env.VUE_APP_AuthUrl, process.env.VUE_APP_AppImgApi)
        })
        let data = {
          results: {
            items: res.data.results.items
          },
          total: res.data.total
        }
        return data
      })
    },
    toDetail(buzzTxId) {
      this.$router.push({
        path: this.$route.path + '/detail',
        query: {
          buzzTxId
        }
      })
    },
    goUser(metaId, zeroAddress) {
      this.$router.push({
        name: 'User2',
        params: {
          txId: metaId
        }
      })
      this.$store.commit('updateOtherUserInfo', {
        metaId,
        zeroAddress
      })
    },
  },
  filters: {
    videoFilters(value) {
      if (!value) return ''
      return `${process.env.VUE_APP_AppImgApi}/metafile/${value}`
    },
    avatarUrl(avatarTx){
      if(avatarTx){
        return `${process.env.VUE_APP_AppImgApi}/metafile/avatar/${avatarTx}`
      }else{
        return `${process.env.VUE_APP_AppImgApi}/metafile/avatar/undefined`
      }
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 600Px;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom: 0.5px solid #C4C5C6;
    .title {
      font-size: 18px;
      color: #000000;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px;
    position: absolute;
    top: 60px;
    bottom: 50px;
    /deep/ .van-list {
      display: flex;
      flex-wrap: wrap;
      margin-right: -10px;
    }
    /deep/ .van-list__finished-text {
      width: 100%;
    }
    .item {
      width: 105px;
      height: 123px;
      margin-bottom: 20px;
      margin-right: 10px;
      position: relative;
      .photo {
        width: 100%;
        height: 105px;
        cursor: pointer;
        border-radius: 6px;
      }
      //&:nth-child(3n) {
      //  margin-right: 0;
      //}
      /deep/ img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
      .icon_img {
        width: 20px;
        height: 20px;
        background-image: url("../../assets/img/user/icon_img.png");
        background-size: 100% 100%;
        position: absolute;
        right: 4px;
        bottom: 20px;
      }
      .icon_video {
        width: 20px;
        height: 20px;
        background-image: url("../../assets/img/user/icon_video.png");
        background-size: 100% 100%;
        position: absolute;
        right: 4px;
        bottom: 20px;
      }
    }
  }
}
</style>